import router from '@/router/index'
import { getToken } from '@/utils/auth'


let arrRoutes = ["/admin/Personalizar", "/marketing/lottery/detail", "/admin/product/add_product", "/admin/store/video_category/add_curriculum", "/admin/store/store_video/AddVideo", "/admin/store/video_category/class_detail", "/admin/store/video_category/course_detail", "/admin/store/store_video/video_detail", "/admin/store/video_category/add_student", "/admin/cms/add_article", "/admin/system/config/system_config_tab/list/1", "/feedback/index/detail", "/admin/wework_admin/detail", "/admin/wework_admin/add", "/admin/store/store_video/add_class"]
function fn(list) {
    for (let i = 0; i < list.length; i++) {
        if (list[i].children != undefined) {
            fn(list[i].children)
        } else {
            arrRoutes.push(list[i].path)
        }
    }
}
if (localStorage.getItem("menus")) {
    const routes = JSON.parse(localStorage.getItem("menus"))
    fn(routes)
}
router.beforeEach((to, from, next) => {
    let token = getToken()
    if (token) {
        if (to.path == "/error") {
            next()
            return
        }
        if (to.path == '/login') {
            next("/")
        } else {
            if (arrRoutes.includes(to.path)) {
                next()
            } else {
                next('/error')
            }
        }
    } else {
        if (to.path == '/login' || to.path == '/admin/regedit') {
            next()
        } else {
            next('/login')
        }
    }
})

router.afterEach((to, from, next) => {

})